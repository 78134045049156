import '../estilos/layouts/menu.css';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";
import { Outlet, Link } from 'react-router-dom';

import "react-bootstrap-submenu/dist/index.css";

const Menu = () =>{
   return(
      <>
      <Navbar className="menuBg" collapseOnSelect expand="lg"  variant="dark" >
      <Container>
      
         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
         <Navbar.Collapse id="responsive-navbar-nav">
         <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">Inicio</Nav.Link>

            <NavDropdownMenu title="Nuestra Provincia" id="collasible-nav-dropdown">
               <NavDropdown.Item as={Link} to="/ubicacion">Ubicacion</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/festividades">Festividades</NavDropdown.Item>
            </NavDropdownMenu>

            <NavDropdownMenu title="La Municipalidad" id="collasible-nav-dropdown">
               <NavDropdown.Item as={Link} to="/misionvision">Misión y Visión</NavDropdown.Item>
               <NavDropdown.Item href="#action/3.1">Alcaldía</NavDropdown.Item>
            <DropdownSubmenu href="#action/3.7" title="Concejo Municipal">
              <NavDropdown.Item as={Link} to="/regidores">Regidores</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/comisiones">Comisiones</NavDropdown.Item>  
            </DropdownSubmenu>
            {/*<DropdownSubmenu href="#action/3.7" title="Funcionarios">
               <NavDropdown.Item href="https://www.gob.pe/institucion/muniquispicanchi/funcionarios" target="_blank">Sub funcionarios</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/funcionarios">Funcionarios</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/declaracionesjuradas">Declaraciones Juradas</NavDropdown.Item>
            </DropdownSubmenu>*/}
               <NavDropdown.Item href="https://www.gob.pe/institucion/muniquispicanchi/funcionarios" target="_blank">Funcionarios</NavDropdown.Item>
            </NavDropdownMenu>

            <NavDropdownMenu title="Servicios Municipales" id="collasible-nav-dropdown">
               <NavDropdown.Item href="https://facilita.gob.pe/t/6983" target="_blank">Mesa de Partes Virtual</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/registrocivil">Registro Civil, Tarifario</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/transito">Transito Circulación Vial, Tarifario</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/segciudadana">Seguridad Ciudadana</NavDropdown.Item>
            </NavDropdownMenu>

            <NavDropdownMenu title="Normatividades" id="collasible-nav-dropdown">
               <NavDropdown.Item as={Link} to="/instrumentosgestion">Instrumentos de Gestion</NavDropdown.Item>
                  <DropdownSubmenu href="#action/3.7" title="Normas Emitidas">
                  <NavDropdown.Item as={Link} to="/resolucionalcaldia">Resolución de Alcaldía</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/decretosalcaldia">Decretos de Alcaldía</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/resoluciongerencia">Resolución de Gerencia Municipal</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/directivasgerencia">Directivas de Gerencia Municipal</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/ordenanzamunicipal">Ordenanzas Municipales</NavDropdown.Item>
               </DropdownSubmenu>
               
               <DropdownSubmenu href="#action/3.7" title="Transparencia">
               <NavDropdown.Item as={Link} to="/presupuestoparticipativo">Presupuesto Participativo</NavDropdown.Item>
               <NavDropdown.Item href="#action/8.1">Proyectos de Inversion</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/participacionciudadana">Participacion Ciudadana</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/actividadesoficiales">Actividades Oficiales</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/rendicioncuentas">Rendición de cuentas</NavDropdown.Item>
               <DropdownSubmenu href="#action/3.7" title="Text to show">
                  <NavDropdown.Item href="#action/9.1">
                     Sub 2
                  </NavDropdown.Item>
               </DropdownSubmenu>
               </DropdownSubmenu>

            </NavDropdownMenu>

           {/* <NavDropdownMenu title="Funcionarios" id="collasible-nav-dropdown">
               <NavDropdown.Item as={Link} to="/funcionarios">Funcionarios</NavDropdown.Item>
               <NavDropdown.Item as={Link} to="/declaracionesjuradas">Declaraciones Juradas</NavDropdown.Item>
               
   </NavDropdownMenu>*/}

            <Nav.Link as={Link} to="/convocatoria">Convocatoria CAS</Nav.Link>
            <Nav.Link as={Link} to="/contacto">Contacto</Nav.Link>
         </Nav>
         </Navbar.Collapse>
      </Container>
      </Navbar>

      <section>
         <Outlet></Outlet>
      </section>
      </>
   );
}
export default Menu;

/*<NavDropdownMenu title="Dropdown 1" id="collasible-nav-dropdown">
               <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
               <DropdownSubmenu href="#action/3.7" title="Text to show">
               <NavDropdown.Item href="#action/8.1">Sub 1</NavDropdown.Item>
               <DropdownSubmenu href="#action/3.7" title="Text to show">
                  <NavDropdown.Item href="#action/9.1">
                     Sub 2
                  </NavDropdown.Item>
               </DropdownSubmenu>
               </DropdownSubmenu>
               </NavDropdownMenu>*/